import out from '../assets/out.svg'
import { useTranslation } from 'react-i18next'
import back from '../assets/back.svg'
import { memo,useCallback, useEffect, useMemo, useState } from 'react'
import { Link,useHistory } from 'react-router-dom'
import Store from 'store'
import icon from '../assets/logo_white_only.png'

//import react pro sidebar components
import { Sidebar, Menu, MenuItem, SubMenu,menuClasses, MenuItemStyles } from 'react-pro-sidebar';

//import icons from react icons
import { FaList, FaRegHeart } from "react-icons/fa";
import { FiHome, FiLogOut, FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi";
import { RiPencilLine } from "react-icons/ri";
import { BiCog } from "react-icons/bi";
import http from '../lib/http'

import './header.scss'

const logout_url=`${process.env.REACT_APP_API_URL}/agentbackend/user/logout`;
const app_url = `${process.env.REACT_APP_URL}`;
const backend_url=`${process.env.REACT_APP_API_URL}`;
const Header = ({ isHome = false, title }) => {
    const { t,i18n } = useTranslation();
    const Theme = 'light' | 'dark';

    const themes = {
        light: {
          sidebar: {
            backgroundColor: '#ffffff',
            color: '#607489',
          },
          menu: {
            menuContent: '#fbfcfd',
            icon: '#0098e5',
            hover: {
              backgroundColor: '#c5e4ff',
              color: '#44596e',
            },
            disabled: {
              color: '#9fb6cf',
            },
          },
        },
        dark: {
          sidebar: {
            backgroundColor: '#0b2948',
            color: '#8ba1b7',
          },
          menu: {
            menuContent: '#082440',
            icon: '#59d0ff',
            hover: {
              backgroundColor: '#00458b',
              color: '#b6c8d9',
            },
            disabled: {
              color: '#3e5e7e',
            },
          },
        },
      };

    // hex to rgba converter
    const hexToRgba = (hex, alpha) => {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
    
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };


    const [collapsed, setCollapsed] = useState(false);
    const [toggled, setToggled] = useState(false);
    const [broken, setBroken] = useState(false);
    const [rtl, setRtl] = useState(false);
    const [hasImage, setHasImage] = useState(false);
    const [theme, setTheme] = useState('dark');

    const changeLanguage = (lng) => {
      console.log("HERE");
      i18n.changeLanguage(lng);
    };
  // handle on RTL change event
//   const handleRTLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setRtl(e.target.checked);
//   };

//   // handle on theme change event
//   const handleThemeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setTheme(e.target.checked ? 'dark' : 'light');
//   };

//   // handle on image change event
//   const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setHasImage(e.target.checked);
//   };
  function goToFrontend(){
    var a_t = user.access_token ;
    var frontend=app_url;
    var backend=backend_url;
    if(user.user.domain.web!==undefined && user.user.domain.web!=""){
      frontend=user.user.domain.web;
    }
    if(user.user.domain.api!==undefined && user.user.domain.api!=""){
      backend=user.user.domain.api;
    }
    window.open(frontend+'pages/ucenter/user-token?token='+a_t+'&bu='+backend);
  }
  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: '13px',
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: '#b6b7b9',
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(themes[theme].menu.menuContent, hasImage && !collapsed ? 0.4 : 1)
          : 'transparent',
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      '&:hover': {
        backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, hasImage ? 0.8 : 1),
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

    const history = useHistory()

    const goBack = useCallback(() => {
        history.push('/home')
    }, [history ])

    const signOut = useCallback(async() => {
        let result= await http({ url: logout_url, method: 'post'}).then(response=>{
          console.log("response",response);
          Store.remove('user')
          history.push('/')
        });
    }, [history])
    const user = Store.get('user');    
    return (
        <>
        <div id="header">
            <Sidebar
                collapsed={collapsed}
                toggled={toggled}
                onBackdropClick={() => setToggled(false)}
                onBreakPoint={setBroken}
                image="https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg"
                rtl={rtl}
                breakPoint="md"
                backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, hasImage ? 0.9 : 1)}
                rootStyles={{
                color: themes[theme].sidebar.color,
                height:'100vh'
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                {/* <SidebarHeader rtl={rtl} style={{ marginBottom: '24px', marginTop: '16px' }} /> */}
                <div style={{ flex: 1, marginBottom: '32px' }}>
      

                    <div className="p-4 d-flex gap-2 justify-content-center align-items-center">
                        <img style={{width:24,height:24}} src={icon} alt="" />
                        <span style={{fontSize:26,color:'white'}}>{t('admin')}</span>
                    </div>
                    <div className="p-1 lang-menu d-flex gap-2 justify-content-center">
                      <button className="lang-btn p-2 px-3" onClick={() => goToFrontend()}>跳到前台</button>
                    </div>
                    <Menu menuItemStyles={menuItemStyles}>
                        <MenuItem component={<Link to="/dashboard" />} >{t('dashboard')}</MenuItem>
                        <MenuItem component={<Link to="/transfer" />} >{t('transfer')}</MenuItem>
                        <MenuItem component={<Link to="/withdraw" />} >{t('balance')}{t('withdrawal')}</MenuItem>
                        <MenuItem component={<Link to="/withdrawrecord" />} >{t('withdrawrecord')}</MenuItem>
                        <MenuItem component={<Link to="/bindings" />} >{t('userbinding')}</MenuItem>
                        <SubMenu label={t('wallet')}>
                          <MenuItem component={<Link to="/transaction-log" />}>{t('walletflow')}</MenuItem>
                          <MenuItem component={<Link to="/fundflow?direction=input" />}>{t('player')}{t('deposit')}</MenuItem>
                          <MenuItem component={<Link to="/fundflow?direction=output" />}>{t('player')}{t('withdrawal')}</MenuItem>
                        </SubMenu>
                        <SubMenu label={t('pendingordermanagement')}>
                            <MenuItem component={<Link to="/task" />}>{t('pendingorder')}</MenuItem>
                            <MenuItem component={<Link to="/newtask" />}>{t('neworder')}</MenuItem>
                            {/* <MenuItem disabled>挂单市场</MenuItem> */}
                        </SubMenu>
                        <MenuItem component={<Link to="/invite" />}>{t('myinvitation')}</MenuItem>
                        {/*<MenuItem component={<Link to="/bindings" />}>玩家绑定</MenuItem>*/}
                        {/* <MenuItem component={<Link to="/proxy" />}>Proxy</MenuItem> */}
                        <SubMenu label={t('setting')}>
                          <MenuItem component={<Link to="/settings" />} >{t('whitelistsettings')}</MenuItem>
                          <MenuItem component={<Link to="/webwhitelistsettings" />} >{t('webwhitelistsettings')}</MenuItem>
                          <MenuItem component={<Link to="/resetpassword" />} >{t('resetpassword')}</MenuItem>
                          <MenuItem component={<Link to="/resetsubpassword" />} >{t('resetsubpassword')}</MenuItem>
                          <MenuItem component={<Link to="/ga_verification" />} >{t('ga_verification')}</MenuItem>
                          <MenuItem component={<Link to="/paytype" />} >{t('paytype')}</MenuItem>
                          <MenuItem component={<Link to="/withdraw_deposit_offer" />} >{t('withdraw_deposit_settings')}</MenuItem>
                        </SubMenu>
                        <MenuItem onClick={signOut} >{t('signout')}</MenuItem>
                    </Menu>
                    <div className="p-4 lang-menu d-flex gap-2">
                      <button className="lang-btn" onClick={() => changeLanguage("zh-cn")}>中</button>
                      <button className="lang-btn" onClick={() => changeLanguage("en")}>Eng</button>
                    </div>
                </div>
                {/* <SidebarFooter collapsed={collapsed} /> */}
                </div>
            </Sidebar>
        </div>
        {broken && (
            <button style={{background:themes[theme].menu.hover.backgroundColor,color:'white'}} className="p-2 mobile-nav-button" onClick={() => setToggled(!toggled)}>
                菜单
            </button>
        )}
        
            {/* {
                isHome ? (
                    <div>
                    <div className="isHome headers">
                        <span className="left">{t('admin')}</span>
                        <span className="right" onClick={signOut}>
                            <img src={out} alt="" className="out-img" />
                            {t('out')}
                        </span>
                    </div>
                    <div className="user-info">{t("welcome_message")}, {user.user.name}</div>
                    </div>
                ): <div className="headers">
                    <i className="back" onClick={goBack}>
                        <img src={back} alt="" />
                    </i>
                    <h3>{title}</h3>
                </div>
                
            } */}
        </>
    )
}

export default memo(Header);